import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from './Home';
import About from './About';
import NavComponent from './home/NavComponent';
import Cost from './resources/Cost';
import History from './resources/History';
import Bill from './resources/Bill.js';
import Problem from './resources/Problem.js';
import IndependentCommission from './resources/IndependentCommission.js';

const CustomLinkExample = () => (
  <Router>
    <div>
        <NavComponent />
      <Route exact path="/" component={Home}/>
      <Route path="/about" component={About} />
      <Route path="/cost-of-gerrymandering" component={Cost} />
      <Route path="/history-of-gerrymandering" component={History} />
      <Route path="/redistricting-bill" component={Bill} />
      <Route path = "/problem-and-solution" component={Problem} />
      <Route path = "/independent-commission" component={IndependentCommission} />
    </div>
  </Router>
);


export default CustomLinkExample;
