import React, { Component } from 'react';
import MyPdfViewer from './MyPdfViewer.js';
import './Bill.css';

class Bill extends Component {
    render() {
      return (
          <div className="page">
            <p className="intro">
            Senate Bill 1093, which provides for citizen redistricting, was introduced in 2007 in the North Carolina General Assembly by legislators who were then in the minority. It did not become law. Several of the bill’s sponsors now hold leadership positions in the Assembly. The only change needed to the bill is to update one date reference (from 2008 to 2020).
            </p>
            <MyPdfViewer />
          </div>
      );
    }
  }

export default Bill;
