import React, { Component } from 'react';
import './MobileHome.css'

class MobileHome extends Component {
  render() {
    return (
        <div>
            <div className="white">
                <h2 className="blue">WELCOME TO</h2>
                <h1>CITIZEN REDISTRICTING NORTH CAROLINA</h1>
                <img class="bkg-image" src="/images/test3.jpg" />
                <p>CRNC is a non-profit, non-partisan grassroots initiative to end gerrymandering in North Carolina.</p>
                <p>We believe the best solution to the problems arising from gerrymandering is to take redistricting out of the hands of politicians, who will invariably seek to draw district lines to their political advantage, and entrust this power to the people of North Carolina.</p>
                <div className="button-align">
                    <a className="mobile-button" href="/problem-and-solution">Learn more about the problem and our solution</a>
                </div>
            </div>
            <div className="grey">
                <p>We will be compiling resources here on our website to make gerrymandering, its history, and impact easier for everyone to understand. </p>
                <div className="button-align">
                    <a className="mobile-button" href="/history-of-gerrymandering">Learn more about the history of gerrymandering</a>
                </div>
            </div>
        </div>
    );
  }
}

export default MobileHome;
