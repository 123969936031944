import React, { Component } from 'react';
import "./Articles.css";

class Problem extends Component {
  render() {
    return (
        <div className="page">
            <div className="head">
                <span className="articletitle">
                    <h2>GERRYMANDERING: THE PROBLEM AND THE SOLUTION</h2>
                </span>
            </div>
            <div className= "article">
                <h5>THE PROBLEM</h5>
                <p>
                Gerrymandering, the drawing of voting districts by politicians to their political advantage, is one of the most critical problems facing North Carolina and the nation today.  In North Carolina, as in most states, the legislature redraws the boundaries for legislative and congressional districts after each census to reflect changes in population.  The party in control of the legislature generally draws the new lines to give its members the greatest number of “safe seats,” districts where that party holds such a commanding advantage in registered voters that elections are unlikely to be close.
                </p>
                <p>
                Gerrymandering is neither new nor limited to one party.  The term dates back to a salamander-shaped district fashioned by Massachusetts Governor Elbridge Gerry in 1812.  Democrats created districts to their benefit during the many years they controlled the North Carolina legislature.  When Republicans took control of the Assembly in 2010, they struck back with redistricting maps favoring their candidates.
                </p>
                <p>
                What has changed in recent years is the development of sophisticated mapping technology that enables politicians to gerrymander more effectively.  There has been a steady decline at both the state and national level in competitive seats that candidates from both parties have a realistic chance of winning.  As the number of safe seats has multiplied, politicians have tilted toward the more extreme positions within their own parties, because their only real fear is losing to a more radical challenger in a primary election.  The result has been increased polarization and dysfunction in the state legislature and in Congress, as there is little incentive for bipartisan cooperation and as centrists prepared to reach across party lines disappear.
                </p>

                <h5>THE SOLUTION</h5>
                <p>
                The way to eliminate gerrymandering is easy:  require districts which (1) are compact, so that irregular lines cannot be drawn to target pockets of one party’s voters; (2) preserve the principle of one person, one vote, so that disparities in voting power are avoided; and (3) minimize the division of cities and counties, so that existing communities are kept intact.  What is difficult is persuading politicians to give up the power to draw the lines.  The party in the minority typically proposes reforms to the system that are thwarted by the party in the majority.  For years Republicans in the state legislature sponsored reform bills that were blocked by the Democrat majority.
                </p>
                <p>
                Since Republicans took control of the Assembly in 2010, Democrats have joined with some Republicans in the North Carolina House to pass bills calling for an independent commission, modeled on a similar body in Iowa, that would oversee the redistricting process.  Despite bipartisan backing in the House, these bills have not made it out of committee in the Senate.  Senate leaders have suggested that it is almost impossible to remove politics even from an ostensibly independent commission, which would be appointed by politicians and whose maps would need to be approved by politicians. 
                </p>
                <p>
                The only realistic way to rid North Carolina of gerrymandering in the near future is through a system that truly insulates redistricting from political considerations.  Fortunately, such a system has already been proposed by the very legislative leaders now opposed to reform.  While in the minority, they sponsored a bill providing for citizen redistricting.  Under the bill, any North Carolina resident could submit a redistricting map utilizing software made available by the state.  Maps that meet certain basic qualifying standards would be scored according to three criteria:  compactness; one person, one vote; and minimizing the number of split counties, cities, and precincts.  Mathematical formulas would be used as the basis for scoring, so the process would be entirely objective.  All maps submitted would be made publicly available.  The highest scoring map for each of the state House, state Senate, and U.S. Congress would be adopted as the basis for the new districts.
                </p>
                <p>
                The draw of this approach is powerful.  Citizens are empowered through technology to draw their own districts.  Politics is taken out of redistricting.  And the process is completely transparent.
                </p>
            </div>
        </div>
    );
}
}

export default Problem;
