import React, { Component } from 'react';
import "./Articles.css";

class IndependentCommission extends Component {
  render() {
    return (
        <div className="page">
            <div className="head">
                <span className="articletitle">
                    <h2>CITIZEN REDISTRICTING VS. INDEPENDENT COMMISSION</h2>
                </span>
            </div>
            <div className= "article">
                <p>
                Most efforts at gerrymandering reform in North Carolina in recent years have been based on establishing an independent commission to oversee the redistricting process. Citizen Redistricting NC has no objection in principle to such a commission taking on this role. Oversight by an independent commission would unquestionably be preferable to the current dysfunctional system, which is marred by blatant partisanship and unending litigation.
                </p>
                <p>
                We are concerned, however, that it may not be feasible in North Carolina’s current political environment to enact a reform on these lines. Numerous independent commission bills have been introduced in the General Assembly, but none has come close to becoming law. We appreciate that reform of the redistricting process is not likely to be achieved until the next census is at hand, when politicians can best assess the risks and benefits of taking a different approach. But in light of the failure of independent commission-based reform to gain traction in the legislature to date, we think that presenting a viable alternative is sensible. Our state cannot afford to wait another 10 years to make meaningful changes to our redistricting system.
                </p>
                <p>
                We also believe there is some basis for the criticism that there can be no such thing as a truly independent commission where politicians are involved in appointing its members. The leading independent commission reform bill calls for members to be appointed by the majority and minority leaders of both houses of the General Assembly. It is almost inconceivable that a process overseen by appointees of such prominent politicians will be insulated from political pressure in a state as polarized as North Carolina, no matter how many well-meaning safeguards are built into the legislation.
                </p>
                <p>
                We are not naïve as to the practical realities of a redistricting system based on citizen mapmaking. Undoubtedly, there will be partisans who will submit maps that they believe will advantage their party. But redistricting will be contested on a level playing field, subject to scoring based on objective standards. Maps proposed by party loyalists from both sides will be scored along with those submitted by unaffiliated residents, GIS buffs, and computer enthusiasts, all of whom will be competing under the same rules. We think our state is best served by a transparent redistricting process that is open to all who wish to participate.
                </p>
                <p>
                We also acknowledge that our proposed reform injects some uncertainty into redistricting. We don’t know what shapes the highest scoring maps will take. It is possible that certain geographic trends, such as the clustering of conservatives in rural areas and progressives in urban areas, may favor one party over the other, at least for some period of time. But we are convinced that a system which takes redistricting out of the hands of politicians and entrusts it to the citizens of our state based on clearly defined criteria will be a vast improvement over our current hyper-partisan and litigious process.
                </p>
            </div>
        </div>
    );
}
}

export default IndependentCommission;
