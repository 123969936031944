import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import './CarouselComponent.css';

class CarouselComponent extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: 0,
      direction: null
    };
  }

  handleSelect(selectedIndex, e) {
    this.setState({
      index: selectedIndex,
      direction: e.direction
    });
  }

  render() {
    const { index, direction } = this.state;

    return (
        <div>
      <Carousel
        activeIndex={index}
        direction={direction}
        onSelect={this.handleSelect}
      >
        <Carousel.Item>
          <img class="bkg-image" src="/images/test3.jpg" />
          <Carousel.Caption id="caption">
            <h2>WELCOME TO</h2>
            <h1>CITIZEN REDISTRICTING</h1>
            <h1>NORTH CAROLINA</h1>
            <p>CRNC is a non-profit, non-partisan grassroots initiative to end gerrymandering in North Carolina.</p>
            <p>We believe the best solution to the problems arising from gerrymandering is to take redistricting out of the hands of politicians, who will invariably seek to draw district lines to their political advantage, and entrust this power to the people of North Carolina.</p>
            <div style={{"margin-top":"20px"}}>
                <a href="/problem-and-solution">Learn more about the problem and our solution</a>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
          <Carousel.Item>
            <img class="bkg-image" src="/images/test3.jpg" />
            <Carousel.Caption id="caption">
              <h1>RESOURCES FOR ACTIVISM</h1>
              <p>We will be compiling resources here on our website to make gerrymandering, its history, and impact easier for everyone to understand. </p>
              <div style={{"margin-top":"20px"}}>
                  <a href="/history-of-gerrymandering">Learn more about the history of gerrymandering</a>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
      </Carousel>
      </div>
    );
  }
}


export default CarouselComponent;
