import React, { Component } from 'react';
import "./Articles.css";

class Cost extends Component {
  render() {
    return (
        <div className="page">
            <div className="head">
                <span className="articletitle">
                    <h2>The Cost of Gerrymandering</h2>

                    <p className="author">Written by Anna Martina</p>
                </span>
                <img className = "authorimg" src="./images/anna.jpg"/>
            </div>
            <div className= "article">
                <p>North Carolina taxpayers have paid a heavy price in recent years as a result of gerrymandering. Challenges to redistricting practices led to a special election in 2016, costing counties in North Carolina a combined $9.5 million. The primary election that year was delayed after federal judges declared the state’s districts maps were racially gerrymandered when drawn. Before North Carolina could hold the primary election, the state had to wait for new district maps to be completed.
                </p>
                <p>
                In addition to increased election costs, litigation expenses arising from gerrymandering have also burdened the state. By the end of 2017, lawsuits against the legislature's redistricting practices beginning in 2011 had cost North Carolina nearly $7 million in order to fight, primarily in fees paid to private law firms engaged by the General Assembly. The practices challenged included both racial and partisan tactics that suppressed or boosted certain voting groups. This price tag was attributable to litigation resulting from the 2011 redistricting efforts alone. North Carolina has actually witnessed nearly 40 lawsuits over district lines within the past 30 years. Throughout those three decades, both parties have had their turn in power, and both have engaged in gerrymandering when given the task to draw district maps after a census. Unless the state’s redistricting practices are reformed, the litigation tab for North Carolina taxpayers is almost certain to rise.
                </p>
                <p>
                In addition to the hefty monetary price of special elections and litigation, North Carolina also faces the long-term cost of voter distrust and governmental dysfunction as a result of partisan redistricting. As the cycle of gerrymandering continues, it increases the number of "safe" seats, those that realistically can only be won by a candidate from one party. The lack of competitive seats enhances partisanship among legislators, as winning the primary by appealing to the most extreme elements in each party becomes critical, and discourages voters, many of whom feel their vote doesn’t matter.
                </p>
                <p>
                It’s time to reform North Carolina’s system for drawing districts by embracing citizen redistricting, which avoids these costs by taking politics out of the process.

                </p>

                <p>
                Source:
                <a href="https://www.journalnow.com/news/elections/state/north-carolina-s-redistricting-cases-cost-taxpayers-million-and-counting/article_5b11c164-9286-59c2-b585-8d26b8d009d2.html"> https://www.journalnow.com/news/elections/state/north-carolina-s-redistricting-cases-cost-taxpayers-million-and-counting/article_5b11c164-9286-59c2-b585-8d26b8d009d2.html </a>
                </p>
            </div>
        </div>
    );
}
}

export default Cost;
