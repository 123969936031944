import React, { Component } from 'react';
import './News.css';
import NewsPreview from './NewsPreview';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

class News extends Component {
  render() {
    return (
        <div>
            <div className="title">
                <h1>CRNC Announcements</h1>
            </div>
            <div className= "container">
                <span>
                    <img className="newsimg" src="/images/news.png"/>
                </span>
                <span className="newsPreviews">
                    <p>Check back in for more announcements!</p>
                    <NewsPreview title="Check out our 'Un-Gerry NC' video!" blurb="We created an animation about our efforts. Check it out below and feel free to share it with friends!" link="https://youtu.be/vgk19uPxZGc"/>
                </span>
            </div>
        </div>
    );
}
}

export default News;
