import React, { Component } from 'react';
import {Card, CardMedia, CardContent, Typography} from '@material-ui/core';
import './NewsPreview.css';
import YouTube from 'react-youtube';

class NewsPreview extends Component {
  render() {
    const opts = {
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };

    return (
        <div className="card">
          <Card style={{"box-shadow" : "none", "border-width":".5px","border":"solid","border-color":"grey"}}>
          <CardContent className="content">
            <Typography className="date" variant="p" component="p">
              {this.props.date}
            </Typography>
            <Typography style={{"margin-bottom" : "0px", "font-weight":"400","font-size":"24px"}} gutterBottom className="title" variant="h5" component="h2">
              {this.props.title}
            </Typography>
            <Typography  variant="p" component="p">
              {this.props.blurb}
            </Typography>
            <YouTube
              videoId="vgk19uPxZGc"
              opts = {opts}
            />
            <a href={this.props.link}>Click here to watch it on Youtube!</a>
          </CardContent>
          </Card>
        </div>
    );
    }
}

export default NewsPreview;
