import React, { Component } from 'react';
import './About.css';
// import { Router, Route, Link } from "react-router-dom";
import NavComponent from './home/NavComponent';
import CarouselComponent from './home/CarouselComponent';
import Bio from './Bio.js';
import Connect from './home/Connect.js';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class About extends Component {
  render() {
    return (
        <div>
            <div className="about">
                <h1>About CRNC</h1>
                <p>CRNC is a non-profit, non-partisan grassroots initiative to end gerrymandering in North Carolina. We believe the best solution to the problems arising from gerrymandering is to take redistricting out of the hands of politicians, who will invariably seek to draw district lines to their political advantage, and entrust this power to the people of North Carolina.</p>
                <div className="bdy">
                    <h4>Active participants in CRNC include:</h4>
                        <div className = "bios">
                            <Bio imgsrc="./images/purshotam.jpg" name="Purshotam Aruwani" position="UNC Chapel Hill Student" bio=""/>
                            <Bio imgsrc="./images/gary.png" name="Gary Cole" position="Community Volunteer"/>
                            <Bio imgsrc="./images/anna.jpg" name="Anna Martina" position="NC State Student" bio=""/>
                            <Bio imgsrc="./images/conrad.jpeg" name="Conrad Mitchell" position="Duke University Student" bio=""/>
                            <Bio imgsrc="./images/dane.png" name="Dane Summers" position="Community Volunteer" bio=""/>
                        </div>
                </div>
            </div>
            <Connect />
        </div>
    );
  }
}

export default About;
