import React, { Component } from 'react';
import './Connect.css';
import NewsPreview from './NewsPreview';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

class Connect extends Component {
  render() {
    return (
        <div className="connect">
            <div className="line">
                <h2>Want to learn more?</h2>
            </div>
            <div className="line">
                <h5>Visit our <a href="https://www.facebook.com/citizenredistrictingnc">Facebook Page</a> for more updates or shoot us an email!</h5>
                <p>Our advocacy effort is student driven. If you are a North Carolina student, particularly if you’re from a district outside of our state’s major cities, and would like to get involved in our reform, we’d love to hear from you.</p>
                <p>We’re also looking for software developers to assist with the redistricting platform we’re working on. If you’d like to volunteer your software expertise to advance citizen redistricting, please let us know.</p>
            </div>
            <div className="buttonline">
                <a id= "connectbutton" href="mailto:citizenredistricting@gmail.com">Connect with us</a>
            </div>
        </div>
    );
}
}

export default Connect;
