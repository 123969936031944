import React, { Component } from 'react';
import {Card, CardMedia, CardContent, Typography} from '@material-ui/core';
import './Bio.css';

class Bio extends Component {
  render() {
    return (
    <div className="bio">
        <img className="bioimg" src={this.props.imgsrc}/>
        <h4 className="name">
          {this.props.name}
        </h4>
        <p className="position">
          {this.props.position}
        </p>
        <p>
          {this.props.bio}
        </p>
      </div>
    );
  }
}

export default Bio;
