import React, { Component } from 'react';
import './App.css';
// import { Router, Route, Link } from "react-router-dom";
import NavComponent from './home/NavComponent';
import CarouselComponent from './home/CarouselComponent';
import Bio from './Bio.js';
import News from './home/News.js';
import Connect from './home/Connect.js';
import MobileHome from './home/MobileHome.js';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import MediaQuery from "react-responsive";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class Home extends Component {
  render() {
    return (
        <div>
        <MediaQuery query="(min-width: 1100px)">
            <CarouselComponent />
        </MediaQuery>
        <MediaQuery query="(max-width: 1099px)">
            <MobileHome />
        </MediaQuery>
            <News />
            <Connect />
        </div>
    );
  }
}

export default Home;
