import React, { Component } from 'react';
import './NavComponent.css';
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

class NavComponent extends Component {
  render() {
    return (
        <div className="mobile-nav">
            <Navbar id="nav">
              <Navbar.Header>
                <Navbar.Toggle />
              </Navbar.Header>
               <Navbar.Collapse>
              <Nav>
                  <NavItem eventKey={1} href="/">
                    Home
                  </NavItem>
                <NavDropdown eventKey={2} title="Gerrymandering" id="basic-nav-dropdown">
                    <MenuItem href="/problem-and-solution" eventKey={2.1}>Problem and Solution</MenuItem>
                    <MenuItem divider />
                    <MenuItem href="/redistricting-bill" eventKey={2.2}>Citizen Redistricting Reform Bill</MenuItem>
                    <MenuItem divider />
                  <MenuItem href="/history-of-gerrymandering" eventKey={2.3}>The History of Gerrymandering</MenuItem>
                  <MenuItem divider />
                  <MenuItem href="/cost-of-gerrymandering" eventKey={2.4}>The Cost of Gerrymandering</MenuItem>
                  <MenuItem divider />
                  <MenuItem href="/independent-commission" eventKey={2.5}>Citizen Redistricting vs. Independent Commission</MenuItem>
                </NavDropdown>
                <NavItem eventKey={3} href="/about">
                  About Us
                </NavItem>
              </Nav>
              </Navbar.Collapse>
          </Navbar>
        </div>
    );
  }
}

export default NavComponent;
