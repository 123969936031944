import React, { Component } from 'react';
import "./Articles.css";

class History extends Component {
  render() {
    return (
        <div className="page">
            <div className="head">
                <span className="articletitle">
                    <h4>The History of Gerrymandering</h4>
                    <h2> RECENT GERRYMANDERING HISTORY IN NORTH CAROLINA: SPOTLIGHT ON THE 12TH CONGRESSIONAL DISTRICT</h2>
                    <p className="author">Written by Purshotam Aruwani</p>
                </span>
                <img className = "authorimg" src="./images/purshotam.jpg"/>
            </div>
            <div className= "article">
                <p>North Carolina’s history is notably plagued with many cases of redrawn districts. Throughout the past 45 years, NC’s district lines have been erased and redefined countless times. Many attribute this alteration of boundaries to the current legislative-majority holder, the Republican Party. However, a more in-depth examination of the history of North Carolina and gerrymandering demonstrates that both parties have been guilty of delineating our state’s districts in a way that skews partisan control over legislation. No district better illustrates this record of political manipulation than the territorial abomination that is North Carolina’s Congressional District 12.
                </p>
                <p>
                Over the course of several decades, North Carolina’s population grew enough to gain a 12th congressional seat after the census in 1990. As the state’s second majority-minority district, it was intended to boost minority representation and better reflect the voter population. In reality, the 12th district is perhaps America's most notorious example of a horribly gerrymandered congressional district. Resulting from a combination of 1980s/1990s judicial and administrative decisions under the federal Voting Rights Act and the partisan politics that have been at the core of redistricting, North Carolina’s 12th district is a blatant manipulation of the district-drawing process. Many overlook the fact that, at the time the district was created, the Democratic Party had control of three vital areas of political power – the office of the governor, the state House, and the state Senate – which made it easy for the party to mold the newly formed 12th district to include a population with a Democratic-majority.
                </p>
                <p>

                Known as the "I-85 district," the 12th congressional district stretched 160 miles across the central Piedmont region of the state, with part of its length no wider than a freeway right-of-way. In its original configuration, the district had a 64 percent African-American majority in population. In 1993, the US Supreme Court ruled in Shaw v. Reno that a racial gerrymander could, in some cases, be considered a violation of the Equal Protection Clause of the US Constitution. However, the Supreme Court did not actually rule that the plans for the 12th district were unconstitutional. The state legislature claimed it was a demographic division of the voter population meant to represent people of the interior Piedmont area, and thus proceeded with their proposed drawing of district lines.
                </p>
                <p>
                This would not, however, be the end of the district plan’s legal odyssey. It was clear that the 12th district was drawn to create an area with an African American voter majority. Initially, the plan was able to avoid rejection on the grounds of racial bias by its intention to “serve as a compelling government interest.” The plan was marketed as a way to remedy the acts of previous racial discrimination – a strategy that allowed the plan to survive its first scrutiny by a federal court. The federal district court (Shaw v. Hunt, 1996) found that the Legislature had intentionally drawn the plan to create two districts where blacks were an effective voting majority. However, it was also found by the district court that this plan served as a way to comply with the Voting Rights Act, and yet again the district map survived a court’s judgment. The plan once again returned to the Supreme Court, and was this time struck down on the grounds that, while compliance with the Voting Rights Act was a compelling interest, the plan was not specifically tailored to achieve this interest.
                </p>

                <p>
                North Carolina's 12th congressional district has been redrawn five times since 1993. Its various twisted and misshapen iterations are shown in the maps at the end of this article. The newest outline was set in February 2016 after a federal court ruled that the lines had been drawn to concentrate black voters into a small number of districts, thereby limiting their influence (a practice known as “packing”). The redrawn map made the 12th a compact district comprising nearly all of Mecklenburg County, except the southeast and a small sliver in the northeast. Due to Charlotte's heavy swing towards the Democratic Party in recent years, the reconfigured 12th district is no less Democratic than its predecessors. Both the Republican and Democratic Party have had reason to seek a gerrymandered district that concentrated African Americans into a single area – the Republicans to dilute their influence and the Democrats to guarantee a safe district seat – further demonstrating the stake that both parties have maintained in North Carolina’s history of gerrymandering.
                </p>

                <p>
                The fight to achieve districts that fairly represent our voter population, districts that are devoid of partisan interest or racial segregation, continues today. On May 22, 2017, US Supreme Court Justice Elena Kagan wrote the majority opinion upholding the decision of the lower court in Cooper v. Harris (2017) that the 12th district, as well as North Carolina’s 1st congressional district, must be re-drawn as they had been subject to an illegal racial gerrymander. The resulting proposed district map was hardly less partisan than the previous one, as it was clear that the party in power (the Republican Party) drew the map with the intent of maintaining its majority. On January 9, 2018, a three-judge panel of the United States District Court for the Middle District of North Carolina ruled that the congressional district map demonstrated unconstitutional partisan gerrymandering and demanded that a new district map be presented immediately. However, on January 18, 2018, the Supreme Court stayed the district court's January 9 order, preventing it from going into effect. The Supreme Court subsequently sent the case back to the lower court for further consideration in light of its ruling in the partisan redistricting case Gill v. Whitford (2018).
                </p>

                <p>
                The tortured history of North Carolina’s 12th congressional district, its borders manipulated by both major parties over the years, underscores the need to take redistricting out of the hands of politicians and entrust it to our state’s citizens under a set of clearly defined, objective standards.
                </p>
            </div>
            <div className="images">
                <img src="./images/history1.png" />
                <p>1993-1998</p>
                <img src="./images/history2.png" />
                <p>1999-2000</p>
                <img src="./images/history3.png" />
                <p>2001-2002</p>
                <img src="./images/history4.png" />
                <p>2002-2013</p>
                <img src="./images/history5.png" />
                <p>2013-2016</p>
            </div>
        </div>
    );
}
}

export default History;
